import React from 'react'
import ReactTooltip from 'react-tooltip'
import Axie from '../Axie/Axie'
import './Card.scss'

const Card = ({title, mode='Classic', isOpponent=false, list, query, state, callback, updateQuery, clearAxies, fetchData, removeAxie, editAxies, saveAxies, loadAxies}) => {
  return (
    <div className={`card${list.length === 0 ? ' inactive' : ''} ${list.length > 12 ? ' large' : ''}`}>
      <div className="card-title" style={{color: isOpponent ? '#ad4438' : null}}>{title}{}</div>
      <div className={`card-actions ${isOpponent ? 'opponent' : ''}`} >
        {!isOpponent && <svg  onClick={loadAxies} stroke="#000" fill="#000" strokeWidth="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" style={{width: '30px', height: '30px'}} data-tip="Load your saved axies" data-effect="solid" data-place="bottom" data-delay-show="1000"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>}
        {!isOpponent && list.length > 0 && <svg className="icon-actions" onClick={saveAxies} stroke="#000" fill="#000" strokeWidth="0" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" data-tip="Save your current axies" data-effect="solid" data-place="bottom" data-delay-show="1000"><path d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"></path></svg>}
        {list.length > 0 && <svg className="icon-actions" onClick={() => editAxies(state, callback)} style={{width: '23px', height: '23px'}} stroke="#000" fill="#000" strokeWidth="0" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" data-tip="Edit current axies" data-effect="solid" data-place="bottom" data-delay-show="1000"><path d="M968.161,31.839c36.456,36.456,36.396,95.547,0,132.003l-43.991,43.991L792.138,75.83l43.991-43.991 C872.583-4.586,931.704-4.617,968.161,31.839z M308.238,559.79l-43.96,175.963l175.963-43.991l439.938-439.938L748.147,119.821 L308.238,559.79z M746.627,473.387v402.175H124.438V253.373h402.204l124.407-124.438H0V1000h871.064V348.918L746.627,473.387z"/></svg>}
        {list.length > 0 && <svg className="icon-actions" onClick={() => clearAxies(callback)}  stroke="#000" fill="#000" strokeWidth="0" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" data-tip="Remove all current axies" data-effect="solid" data-place="bottom" data-delay-show="1000"><path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"/></svg>}
      </div>
      {
        list.length > 0 ?
        <>
            {
              list.map((axie, index) => (
                <Axie mode={mode} axie={axie} key={axie.id} removeAxie={() => removeAxie(state, callback, axie.id)} isLarge={list.length > 15} />
                
                ))
            }
        </>
        :
        <div className="area-input">
          <textarea cols="30" rows="8" value={query} placeholder="Input axie list (in any format)" onChange={(e) => updateQuery(e.target.value, callback)}></textarea>
          <button className="btn-fetch" onClick={() => fetchData(state, callback)}>Fetch</button>
        </div>
      }
      
      <ReactTooltip />
    </div>
  )
}

export default Card