import React from 'react'
import ReactGA from 'react-ga4'
import { useEffect, useState } from 'react';
import Card from '../../Components/Card/Card';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import './Scouter.scss'

const Scouter = () => {

  const [myAxies, setMyAxies] = useState({query: '', list:[]})
  const [enemyAxies, setEnemyAxies] = useState({query: '', list:[]})
  const [mode, setMode] = useState('Origin')

  useEffect(() => {

    ReactGA.send(window.location.pathname);
    const savedMode = localStorage.getItem('mode')
    if (savedMode) {
      setMode(savedMode)
    }
  }, [])
  

  const updateQuery = (query, callback) => {
    callback(prevState => ({
      ...prevState,
      query: query
    }))
  }

  const switchMode = () => {
    setMode(prevMode => { 
      const newMode = prevMode === 'Classic' ? 'Origin' : 'Classic'
      localStorage.setItem('mode', newMode)
      return newMode
    })
  }

  const fetchData = (state, callback) => {
    const regexp = /([0-9])\w+/g
    const array = [...new Set([...state.query.matchAll(regexp)].map(el => el[0]))]
    if (array.length > 0) {
      callback({
        query: state.query,
        list: array.map(id => (
          {
            id: id,
            url: `https://assets.axieinfinity.com/axies/${id}/axie/axie-full-transparent.png`,
            origin_url: `https://axiecdn.axieinfinity.com/axies/${id}/axie/axie-full-transparent.png`
          })
        )
      })
    }
  }

  const removeAxie = (state, callback, id) => {
    let newList = state.list.filter(axie => axie.id !== id)
    callback({
      query: state.query,
      list: [...newList]
    })
  }

  const editAxies = (state, callback) => {
    callback({
      query: state.query,
      list: []
    })
  }

  const clearAxies = (callback) => {
    callback({query: '', list:[]})
  }

  const saveAxies = () => {
    localStorage.setItem('savedAxies', JSON.stringify(myAxies.list.map(ax => ax.id)))
    NotificationManager.success('Axies saved successfully');
  }

  const loadAxies = () => {
    let savedAxies = localStorage.getItem('savedAxies')
    if (savedAxies) {
      setMyAxies({
        query: '',
        list: JSON.parse(savedAxies).map(id => (
          {
            id: id,
            url: `https://assets.axieinfinity.com/axies/${id}/axie/axie-full-transparent.png`,
            origin_url: `https://axiecdn.axieinfinity.com/axies/${id}/axie/axie-full-transparent.png`
          })
        )
      })
      NotificationManager.success('Axies loaded successfully');
    } else {
      NotificationManager.error('No saved axies to load')
    }
    
  }

  // const openHistory = () => {
  //   setHistoryCollapsed(false)
  // }

  // const closeHistory = () => {
  //   setHistoryCollapsed(true)
  // }



  return (
    <div className="scouter">
      <div className="switch" onClick={switchMode}>
        {mode}
      </div>
      <div className="previews">
        <div className="preview-area">
          <Card title='My axies' mode={mode} editAxies={editAxies} loadAxies={loadAxies} saveAxies={saveAxies} removeAxie={removeAxie} list={myAxies.list} query={myAxies.query} state={myAxies} callback={setMyAxies} clearAxies={clearAxies} updateQuery={updateQuery} fetchData={fetchData} />
        </div>
        <div className="preview-area opponent">
          <Card title='Opponent axies' mode={mode} editAxies={editAxies} removeAxie={removeAxie} isOpponent={true} list={enemyAxies.list} query={enemyAxies.query} state={enemyAxies} callback={setEnemyAxies} clearAxies={clearAxies} updateQuery={updateQuery} fetchData={fetchData} />
        </div>
      </div>
      {/* <MatchHistory sendNotification={(type, message) => NotificationManager[type](message)} collapsed={historyCollapsed} openHistory={openHistory} closeHistory={closeHistory} /> */}
      <NotificationContainer/>
    </div>
  )
}

export default Scouter