import React from 'react'
import './Header.scss'
import ReactTooltip from 'react-tooltip';

const Header = () => {
  return (
    <div className="header">
      <ReactTooltip />
      <div className="logo">Axie sniper by Maigal</div>  
      {/* <div className="help" data-tip="This tool helps you scout your opponent's axies to prepare your lineup" data-border="true" data-border-color="#3A3F50" data-background-color="#282B39" data-effect="solid" delay-show="1000" data-place="bottom">??</div> */}
      <div>
        <span>Got a suggestion or bug report? Message <strong>Maigal#3936</strong> on Discord</span>
      </div>
    </div>
  )
}

export default Header