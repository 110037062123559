import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Scouter from './Pages/Scouter/Scouter';
import Header from './Components/Header/Header';
import ReactGA from 'react-ga4'


ReactGA.initialize('G-S63F0K4VHS')

ReactDOM.render(
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Scouter />} />
      <Route path="preview" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
  ,document.getElementById('root')
);
